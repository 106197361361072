<template>
  <transition name="modal">
    <div class="modal-mask" @click="$emit('close')">
      <div class="modal-wrapper">
        <div class="modal-container custom-modal" @click.stop> 
          <button class="uk-modal-close-default uk-close" type="button" uk-icon="close" @click="$emit('close')"></button>
          <div class="modal-header">
            <div class="logo-wrapper">
              <img alt="logo" src="https://pics.seeds.com.tw/bemaster/hk_bemaster_logo_header.png">
            </div>
            <div class="f-right">
              
            </div>
          </div>

          <div class="modal-body">
            <slot name="body">
              <div :class="classObject">
                {{ msg || '未知的錯誤' }}
              </div>
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <button type="submit" @click="$emit('close')">
                確定
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>



<style scoped>
/* local styles */
.success-msg {
  color: #6a6a6a;
}

.modal-body .alert {
  color: #f33232;
}
</style>



<script>
export default {
  name: 'AlertModal',
  props: {
    msg: String,
    isError: {
    type: Boolean,
    default: true
    }
  },
  computed: {
    classObject: function () {
      return {
        't-center': true,
        'alert': this.isError,
        'success-msg': !this.isError
      }
    }
  }
}
</script>

